import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { AreaChart, LinlinearGradiente, YAxis, XAxis, CartesianGrid, Tooltip, Area } from 'recharts';
import * as THREE from 'three/build/three.module.js';

import Logo from '../../assets/images/logo.png'
import LogoM from '../../assets/images/mobile/logo.png'
import LogoM2 from '../../assets/images/mobile/logo-m.png'
import Close from '../../assets/images/mobile/close.png'
import Meun from '../../assets/images/mobile/menu.png'
import Approach from '../../assets/images/approach.png'
import ApproachM from '../../assets/images/mobile/approach.png'
import Bond from '../../assets/images/bonding.png'
import Stake from '../../assets/images/staking.png'
import LogoF from '../../assets/images/logo-f.png'
import LogoFM from '../../assets/images/mobile/logo-f.png'
import To from '../../assets/images/to.png'
import Echarts from '../../assets/images/echarts.svg'
import Token1 from '../../assets/images/tokens/token1.png'
import Token2 from '../../assets/images/tokens/token2.png'
import Token3 from '../../assets/images/tokens/token3.png'
import Token4 from '../../assets/images/tokens/token4.png'
import Treasury from '../../assets/images/treasury.png'
import TreasuryM from '../../assets/images/mobile/treasury.png'
import Mission1 from '../../assets/images/mission/mission1.png'
import Mission2 from '../../assets/images/mission/mission2.png'
import Mission3 from '../../assets/images/mission/mission3.png'
import Why from '../../assets/images/why.png'
import WhyM from '../../assets/images/mobile/why.png'
import ParticipateL from '../../assets/images/participate-l.png'
import ParticipateR from '../../assets/images/participate-r.png'
import ParticipateLM from '../../assets/images/mobile/participate-l.png'
import ParticipateRM from '../../assets/images/mobile/participate-r.png'
import RoadmapItem from '../../assets/images/roadmapItem.png'
import Next from '../../assets/images/next.png'
import Pre from '../../assets/images/pre.png'
import './home.scss'
// import '../../bannerBg.js'

function TransitionDown(props) {
	return <Slide {...props} direction="down" />;
}

export default function Home() {

	const isSmallerScreen = useMediaQuery({ maxWidth: 1080 });
	const tab = ['Carbon Credit Pricing', 'Emissions trading worldwide']
	const timeTab = ['2021', '2022', '2023']
	const [currTimeTab, setCurrTimeTab] = useState(1);
	const [flag, setFlag] = useState(true);
	const [currMap, setcurrMap] = useState(0);
	const [showDrawer, setShowDrawer] = useState(false);
	const width = window.innerWidth
	useEffect(() => {
		if (flag) {
			init();
			animate();
			setFlag(false)
		}
		// const bannerDom = document.getElementById("banner")
		// const canvasDom = document.getElementsByName("canvas")
		// console.log(bannerDom.childNodes)
		// if (bannerDom.childNodes > 1) {
		// 	bannerDom.childNodes.forEach((node, i) => {
		// 		if (i > 1) {
		// 			bannerDom.removeChild(bannerDom.childNodes[i])
		// 		}
		// 	})
		// }
	}, [width])

	var renderer, scene, camera, circle, skelet, particle;
	const innerheigth = window.innerHeight // isSmallerScreen ? 590 : 700
	const init = () => {
		renderer = ''
		renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		renderer.setPixelRatio((window.devicePixelRatio) ? window.devicePixelRatio : 1);
		renderer.setSize(window.innerWidth, innerheigth);
		renderer.autoClear = false;
		renderer.setClearColor(0x000000, 0.0);
		document.getElementById('banner').appendChild(renderer.domElement);

		scene = new THREE.Scene();

		camera = new THREE.PerspectiveCamera(75, window.innerWidth / innerheigth, 1, 1000);
		camera.position.z = 400;
		scene.add(camera);

		circle = new THREE.Object3D();
		skelet = new THREE.Object3D();
		particle = new THREE.Object3D();

		scene.add(circle);
		scene.add(skelet);
		scene.add(particle);

		var geometry = new THREE.TetrahedronGeometry(2, 0);
		var geom = new THREE.IcosahedronGeometry(7, 1);
		var geom2 = new THREE.IcosahedronGeometry(15, 1);

		var material = new THREE.MeshPhongMaterial({
			color: 0xffffff,
			shading: THREE.FlatShading
		});

		for (var i = 0; i < 1000; i++) {
			var mesh = new THREE.Mesh(geometry, material);
			mesh.position.set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize();
			mesh.position.multiplyScalar(90 + (Math.random() * innerheigth));
			mesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2);
			particle.add(mesh);
		}

		var mat = new THREE.MeshPhongMaterial({
			color: 0xffffff,
			shading: THREE.FlatShading
		});

		var mat2 = new THREE.MeshPhongMaterial({
			color: 0xFFFFFF,
			wireframe: true,
			side: THREE.DoubleSide

		});

		var planet = new THREE.Mesh(geom, mat);
		planet.scale.x = planet.scale.y = planet.scale.z = 16;
		circle.add(planet);
		var planet2 = new THREE.Mesh(geom2, mat2);
		planet2.scale.x = planet2.scale.y = planet2.scale.z = 10;
		skelet.add(planet2);
		var ambientLight = new THREE.AmbientLight(0x043d31);
		scene.add(ambientLight);
		var lights = [];
		lights[0] = new THREE.DirectionalLight(0x1C7001, 1);
		lights[0].position.set(1, 0, 0);
		lights[1] = new THREE.DirectionalLight(0x1C7001, 1);
		lights[1].position.set(0.75, 1, 0.5);
		lights[2] = new THREE.DirectionalLight(0x1C7001, 1);
		lights[2].position.set(-0.75, -1, 0.5);
		scene.add(lights[0]);
		scene.add(lights[1]);
		scene.add(lights[2]);
		window.addEventListener('resize', onWindowResize, false);
	};

	const onWindowResize = () => {
		camera.aspect = window.innerWidth / innerheigth;
		camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth, innerheigth);
	}

	const animate = () => {
		requestAnimationFrame(animate);
		particle.rotation.x += 0.0000;
		particle.rotation.y -= 0.0040;
		circle.rotation.x -= 0.0020;
		circle.rotation.y -= 0.0030;
		skelet.rotation.x -= 0.0010;
		skelet.rotation.y += 0.0020;
		renderer.clear();
		renderer.render(scene, camera)
	};

	const [open, setOpen] = React.useState(false);
	const [transition, setTransition] = React.useState(undefined);

	const handleClick = (Transition) => () => {
		setTransition(() => Transition);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handlerPre = () => {
		const curr = currMap - 1
		if (curr < 0) {
			setcurrMap(6)
		} else {
			setcurrMap(curr)
		}
	}
	const handlerNext = () => {
		const curr = currMap + 1
		if (curr > 6) {
			setcurrMap(0)
		} else {
			setcurrMap(curr)
		}
	}

	return (
		<div id="home-view" >
			<Snackbar
				open={open}
				onClose={handleClose}
				autoHideDuration={3000}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				TransitionComponent={transition}
				message="Coming soon... "
				key={transition ? transition.name : ''}
			/>
			{
				showDrawer &&
				<div className='drawer'>
					<Box display='flex' flexDirection='column' alignItems='center'>
						<img src={LogoM2} style={{ marginTop: '72px', height: '80px' }} />
						<div className='menuList'>
							<a href='' className='menuItem font-14 color1' onClick={() => setShowDrawer(false)}>Home</a>
							<a href='https://docs.gruendao.finance/' target="_blank" className='menuItem color1 font-14' onClick={() => setShowDrawer(false)}> Documentation</a>
							<a href='https://discord.gg/w7nXvR9Vg3' target="_blank" className='menuItem color1 font-14' onClick={() => setShowDrawer(false)}>Community</a>
							<a href='https://medium.com/@gruendao' target="_blank" className='menuItem color1 font-14' onClick={() => setShowDrawer(false)}>Media</a>
						</div>
						<img src={Close} className='mt-30' style={{ height: '48px' }} onClick={() => setShowDrawer(false)} />
					</Box>
				</div>
			}
			<Box className="bannerBox" id='top' name="top">
				<div className="banner" id='banner' style={{ height: innerheigth }}>
					<Box className='bannerContainer'>
						<Box className='containerBox' display='flex' flexDirection='column' style={{ height: innerheigth }}>
							{
								isSmallerScreen ?
									<Box display='flex' justifyContent='space-between' alignItems='center' className='header'>
										<img className='logo' src={LogoM} height="30" />
										<Box display='flex' alignItems='center'>
											<Box display='flex' justifyContent='center' alignItems='center' className='invest font-14 font-family-Futura-Bold font-weight-b'
												onClick={handleClick(TransitionDown)}>Invest</Box>
											<img className='mr-16' src={Meun} height="30" onClick={() => setShowDrawer(true)} />
										</Box>
									</Box>
									:
									<Box display='flex' justifyContent='space-between' alignItems='center' className='header'>
										<img className='logo' src={Logo} />
										<Box display='flex' alignItems='center' className='menuList'>
											<a href='' className='menuItem font-14'>Home</a>
											<a href='https://docs.gruendao.finance/' target="_blank" className='menuItem font-14'> Documentation</a>
											<a href='https://discord.gg/w7nXvR9Vg3' target="_blank" className='menuItem font-14'>Community</a>
											<a href='https://medium.com/@gruendao' target="_blank" className='menuItem font-14'>Media</a>
										</Box>
										<Box display='flex' justifyContent='center' alignItems='center' className='invest font-14 font-family-Futura-Bold font-weight-b'
											onClick={handleClick(TransitionDown)}>Invest</Box>
									</Box>
							}
							<Box display='flex' justifyContent='center'>
								<div className='container textCenter'>
									<div className='title font-28'>Opening access to carbon finance</div>
									<div className='desc mt-12 font-36 font-weight-b font-family-Futura-Bold'>
										GrüenDAO develops financial markets for the carbon economy
									</div>
								</div>
							</Box>
						</Box>
					</Box>
				</div>
			</Box>
			<Box className='' display='flex' justifyContent='center'>
				<div className='containerBox'>
					<Box className='approach color2' display='flex' flexDirection='column' alignItems='center'>
						<div className='title-48 font-weight-b font-family-Futura-Bold'>
							What is GrüenDAO
						</div>
						<div className='desc mt-10 font-20 textCenter'>
							GrüenDAO is a collective that develops and operates a decentralized market infrastructure for creation, management, trading, and investments in carbon assets. The collective is designed as an open, equitable, and collaborative ecosystem that will enable easy and active participation for its members in carbon markets.<br /><br />
							GrüenDAO fights climate change by investing in and monetizing carbon credits and climate change technologies.
						</div>
						{
							isSmallerScreen ?
								<img src={ApproachM} className='mt-30' height="100" />
								:
								<img src={Approach} className='mt-44' />
						}
					</Box>
					<Box display='flex' justifyContent='center' className='tokenList'>
						<Box display='flex' flexDirection='column' alignItems='center' className='tokenItem'>
							<img src={Token1} style={{ height: isSmallerScreen ? "90px" : "180px" }} />
							<div className='tokenTitle mt-30 color2 font-16 font-weight-b font-family-Futura-Bold'>GRUEN</div>
							<div className='tokenDesc mt-12 color7 textCenter'>
								The system token of GrüenDAO for staking, wrapping, etc.
							</div>
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' className='tokenItem'>
							<img src={Token2} style={{ height: isSmallerScreen ? "90px" : "180px" }} />
							<div className='tokenTitle mt-30 color2 font-16 font-weight-b font-family-Futura-Bold'>GruenMIRROR</div>
							<div className='tokenDesc mt-12 color7 textCenter'>
								The first digital asset for carbon allowances.
							</div>
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' className='tokenItem'>
							<img src={Token3} style={{ height: isSmallerScreen ? "90px" : "180px" }} />
							<div className='tokenTitle mt-30 color2 font-16 font-weight-b font-family-Futura-Bold'>GruenSHARE</div>
							<div className='tokenDesc mt-12 color7 textCenter'>
								The management token in GrüenDAO for vote and reward.
							</div>
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' className='tokenItem'>
							<img src={Token4} style={{ height: isSmallerScreen ? "90px" : "180px" }} />
							<div className='tokenTitle mt-30 color2 font-16 font-weight-b font-family-Futura-Bold'>GruenCC</div>
							<div className='tokenDesc mt-12 color7 textCenter'>
								Reference token backed by compliance carbon credits where each token represents 1 tonne of CO2 emissions.
							</div>
						</Box>
					</Box>
					<Box display='flex' flexDirection='column' alignItems='center' className=''>
						<div className='whyBox color2'>
							<div className='title-48 font-weight-b font-family-Futura-Bold textCenter'>
								Why Digital Carbon Assets
							</div>
							<div className='whyDesc mt-48 font-20 textCenter'>
								<div className='whyDescItem'>
									Climate change drives the world's greatest economic and wealth reallocation event, which will make carbon assets among the world's largest asset classes, accounting for 10-20% of the world's GDP*. Yet, the present carbon markets are underdeveloped, fragmented, and inefficient.
								</div>
								<div className='whyDescItem'>
									GrüenDAO is developing carbon currency, carbon financial assets, and carbon banking and capital markets to support the growth and development of sustainable low-carbon economy.
								</div>
							</div>
							<div className='tips mt-24 color7 font-family-Futura-Medium-Italic'>
								* SwissRe, "Swiss Re Institute's stress-test analysis," 2021; J. Bednar, M. Obersteiner and A. Baklan, "Operationalizing the net-negative carbon," Nature, no. 596, p. 377–383, 2021; Koberle A., et al. “The cost of mitigation revisited”, 2021
							</div>
						</div>
						<Box className='echarts color2' display='flex' flexDirection='column' alignItems='center'>
							<div className='title-48 font-weight-b font-family-Futura-Bold textCenter'>
								The Economics of Climate Change
							</div>
							<div className='echartDesc mt-48 textLeft font-16 line-height-21'>
								Carbon allowance pricing is driven by strong fundamentals. As demand grows and supply shrinks, all markets for carbon credits converge and rise. Carbon transition requires financial and investment tools for carbon asset development, creating a long-term secular carbon investment class.
							</div>
							<img src={Echarts} className='mt-24' />
							{/* <div className='echartDesc mt-24 font-16 color2'>
							Carbon allowance pricing is driven by strong fundamentals. Demand increases, supply shrinks, all markets rise.
						</div> */}
						</Box>
					</Box>
					<div className='treasuryBox'>
						<Box display='flex' className='treasury color2'>
							<div className='treasury-l'>
								<div className='title-48 ml-4 font-weight-b font-family-Futura-Bold'>
									What Can GrüenDAO Do
								</div>
								<div className='treasuryDesc ml-4 mt-18 font-16 line-height-21'>
									GrüenDAO enables its members to trade and invest in carbon assets. Our protocol develops essential financial tools for carbon financial economy
								</div>
								<div className='treasuryDesc2 mt-24 font-14 color4 line-height-19'>
									- Carbon assets trading and investing<br />
									- Carbon reserve currency<br />
									- Carbon banking and capital markets<br />
									- Carbon funding for entrepreneurs and project operators
								</div>
							</div>
							<div className='treasury-r'>
								<img src={isSmallerScreen ? TreasuryM : Treasury} />
							</div>
						</Box>
					</div>
					<Box display='flex' flexDirection='column' alignItems='center' className='mission color2'>
						<div className='title-48 font-weight-b font-family-Futura-Bold textCenter'>
							GrüenDAO<br />Mission
						</div>
						<Box className='missionList textCenter' display='flex'>
							<div className='missionItem'>
								<Box display='flex' justifyContent='center' alignItems='flex-end' style={{ height: '220px' }}>
									<img src={Mission1} />
								</Box>
								<div className='missionDesc font-16 color4 line-height-19'>
									Create a positive environmental impact through efficient carbon markets
								</div>
							</div>
							<div className='missionItem'>
								<Box display='flex' justifyContent='center' alignItems='flex-end' style={{ height: '220px' }}>
									<img src={Mission2} />
								</Box>
								<div className='missionDesc font-16 color4 line-height-19'>
									Open access to the carbon economy
								</div>
							</div>
							<div className='missionItem'>
								<Box display='flex' justifyContent='center' alignItems='flex-end' style={{ height: '220px' }}>
									<img src={Mission3} />
								</Box>
								<div className='missionDesc font-16 color4 line-height-19'>
									Create new investment and funding channels for carbon assets
								</div>
							</div>
						</Box>
					</Box>
					<Box className='why' display='flex'>
						<div className='why-l'>
							<img src={isSmallerScreen ? WhyM : Why} />
						</div>
						<div className='why-r color2'>
							{
								isSmallerScreen ?
									<div className='title-48 font-weight-b font-family-Futura-Bold'>
										Why Join GrüenDAO Now
									</div>
									:
									<div className='title-48 mt-10 font-weight-b font-family-Futura-Bold'>
										Why Join <br />GrüenDAO Now
									</div>
							}
							<div className='desc mt-12 font-16 font-weight-4 font-family-Futura-Medium-Italic line-height-21'>
								The present markets have not yet priced-in the economics of carbon transition. The carbon economy will inevitably expand, by an estimated multiple of 100-150X in the mid-term horizon.* This is a massive opportunity to enter the market early and actively shape the infrastructure supporting the future largest asset class.<br /><br />
								This situation opens a host of new possibilities for those with the foresight that investing in carbon assets does not only help to solve the climate crisis, but also offers substantial profits.
							</div>
							<div className='desc2 mt-12 color7 font-weight-4 font-family-Futura-Medium-Italic'>
								* Based on datasets and projections from ICap, McKinsey, Refinitiv; accounting for the size of the carbon allowance and carbon offset markets
							</div>
						</div>
					</Box>
					<Box className='participateBox' display='flex' flexDirection='column' alignItems='center' >
						<Box display='flex' alignItems='center'>
							{
								isSmallerScreen ?
									<div style={{ marginRight: '2px' }}>
										<img src={ParticipateLM} style={{ height: '14px' }} />
									</div>
									:
									<div className='mr-16'>
										<img src={ParticipateL} />
									</div>
							}
							<div className='title-48 color2 font-weight-b font-family-Futura-Bold textCenter'>
								How to Participate
							</div>
							{
								isSmallerScreen ?
									<div style={{ marginLeft: '2px' }}>
										<img src={ParticipateRM} style={{ height: '14px' }} />
									</div>
									:
									<div className='ml-16'>
										<img src={ParticipateR} />
									</div>
							}
						</Box>
						<Box display='flex' className='participate mt-30 color4 font-16 line-height-21'>
							<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'
								className='participateItem' onClick={handleClick(TransitionDown)}>
								{/* <img src={Bond} /> */}
								<div className='bond'></div>
								<div className='participateDesc mt-16'>Bonding</div>
							</Box>
							<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'
								className='participateItem' onClick={handleClick(TransitionDown)}>
								{/* <img src={Stake} /> */}
								<Box display='flex' alignItems='center' style={{ height: '50px' }}>
									<div className='stake'></div>
								</Box>
								<div className='participateDesc mt-16'>Staking</div>
							</Box>
						</Box>
					</Box>
				</div>
			</Box>
			<Box className='roadmapBox'>
				<div className='title-48 color2 font-weight-b font-family-Futura-Bold textCenter'>
					Roadmap
				</div>
				<div className='roadmap mt-12'>
					<Box display='flex' justifyContent='center'>
						<Box display='flex' className="containerBox">
							<div className='timeTab'>
								{
									timeTab.map((time, index) =>
										<Box className='tabItem' display='flex' alignItems='center' key={index}
											onClick={() => setCurrTimeTab(index)}>
											<Box className='timeheadeBox' display='flex' justifyContent='flex-end'>
												<div className={`timeheade ${currTimeTab == index ? 'timeheadeActive' : ''}`}></div>
											</Box>
											<div className={`time ml-20 color2 ${currTimeTab == index ? 'font-18 font-weight-b font-family-Futura-Bold' : 'font-14'}`}>
												{time}
											</div>
										</Box>
									)
								}
							</div>
							{
								isSmallerScreen ?
									<div className='roadmapBoxContainer-m'>
										<div className='roadMapBg'></div>
										{
											currTimeTab == 0 ?
												<Box className='roadmapBoxList' display='flex'>
													<div className='roadmapItem'>
														<img src={RoadmapItem} className='roadmapIcon' />
														<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
															display='flex' alignItems='center'>Q4 2021: Validation</Box>
														<div className='roadmapContentList mt-28 font-14 color8'>
															<Box className='roadmapContent' display='flex'>
																<div className='timeheade'></div>
																<div className='ml-10 roadmapDesc'>
																	Market validation, feasibility, market infrastructure design and planning
																</div>
															</Box>
															<Box className='roadmapContent' display='flex'>
																<div className='timeheade'></div>
																<div className='ml-10 roadmapDesc'>
																	Team build out for technical, operational, executive, and market expertise
																</div>
															</Box>
															<Box className='roadmapContent' display='flex'>
																<div className='timeheade'></div>
																<div className='ml-10 roadmapDesc'>
																	Technology and executive capacity
																</div>
															</Box>
															<Box className='roadmapContent' display='flex'>
																<div className='timeheade'></div>
																<div className='ml-10 roadmapDesc'>
																	Partnerships, tools, and networks set up
																</div>
															</Box>
														</div>
													</div>
												</Box>
												:
												(
													currTimeTab == 1 ?
														<Box className='roadmapBoxList' display='flex'>
															<img className='pre' src={Pre} style={{ height: '38px' }} onClick={() => { handlerPre() }} />
															<img className='next' src={Next} style={{ height: '38px' }} onClick={() => { handlerNext() }} />
															{
																currMap == 0 &&
																<div className='roadmapItem'>
																	<img src={RoadmapItem} className='roadmapIcon' />
																	<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																		display='flex' alignItems='center'>Q1 2022: GrüenDAO launch</Box>
																	<div className='roadmapContentList mt-28 font-14 color8'>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				GrüenDAO
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Launch the Initial GrüenDAO system and mirror carbon tokens
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				GrüenDAO Dapp launches
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Treasury formation for initial GRUENCC token launch
																			</div>
																		</Box>
																	</div>
																</div>
															}
															{
																currMap == 1 &&
																<div className='roadmapItem'>
																	<img src={RoadmapItem} className='roadmapIcon' />
																	<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																		display='flex' alignItems='center'>Q2 2022: GRUENCC token launch for carbon allowances</Box>
																	<div className='roadmapContentList mt-28 font-14 color8'>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Infrastructure for tokenization
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Initiation of formal infrastructure for carbon credit management
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Facility for carbon futures management, including brokerage, custody, audit, trading
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Launch of GruenCC reference token backed by carbon allowances
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Token listing on designated exchanges
																			</div>
																		</Box>
																	</div>
																</div>
															}
															{
																currMap == 2 &&
																<div className='roadmapItem'>
																	<img src={RoadmapItem} className='roadmapIcon' />
																	<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																		display='flex' alignItems='center'>Q2 2022: DAO governance</Box>
																	<div className='roadmapContentList mt-28 font-14 color8'>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				DAO proposal for governance mechanism for oversight, treasury management, disclosure, protocol management
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				DAO Proposal for protocol development
																			</div>
																		</Box>
																	</div>
																</div>
															}
															{
																currMap == 3 &&
																<div className='roadmapItem'>
																	<img src={RoadmapItem} className='roadmapIcon' />
																	<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																		display='flex' alignItems='center'>Q3 2022: DAO market infrastructure platform development</Box>
																	<div className='roadmapContentList mt-28 font-14 color8'>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Begin developing own native DAO tooling and platform for carbon credit asset management
																			</div>
																		</Box>
																	</div>
																</div>
															}
															{
																currMap == 4 &&
																<div className='roadmapItem'>
																	<img src={RoadmapItem} className='roadmapIcon' />
																	<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																		display='flex' alignItems='center'>Q3 2022: Carbon offset tokenization</Box>
																	<div className='roadmapContentList mt-28 font-14 color8'>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Carbon offset tokenization infrastructure
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Carbon offset governance evolution to encompass carbon offset asset type
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Carbon offset decision protocol
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Treasury formation for carbon offset token launch
																			</div>
																		</Box>
																	</div>
																</div>
															}
															{
																currMap == 5 &&
																<div className='roadmapItem'>
																	<img src={RoadmapItem} className='roadmapIcon' />
																	<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																		display='flex' alignItems='center'>Q4 2022: Carbon offset token launch</Box>
																	<div className='roadmapContentList mt-28 font-14 color8'>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Infrastructure for carbon offset tokenization
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Carbon offset credit pipeline
																			</div>
																		</Box>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Token listing
																			</div>
																		</Box>
																	</div>
																</div>
															}
															{
																currMap == 6 &&
																<div className='roadmapItem'>
																	<img src={RoadmapItem} className='roadmapIcon' />
																	<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																		display='flex' alignItems='center'>Q4 2022: DAO protocol and governance development</Box>
																	<div className='roadmapContentList mt-28 font-14 color8'>
																		<Box className='roadmapContent' display='flex'>
																			<div className='timeheade'></div>
																			<div className='ml-10 roadmapDesc'>
																				Community consultation on strategic development, initiatives, management, and protocol evolution
																			</div>
																		</Box>
																	</div>
																</div>
															}
														</Box>
														:
														<Box className='roadmapBoxList' display='flex'>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q1 2023 onwards: Value chain integration</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Value chain protocol development to vertically integrate the platform, including
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			New carbon asset type development
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Carbon credit creation
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Project development
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Carbon credit management
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Trading
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Redemption
																		</div>
																	</Box>
																</div>
															</div>
														</Box>
												)
										}
									</div>
									:
									<div className='roadmapBoxContainer'>
										{
											currTimeTab == 0 ?
												<Box className='roadmapBoxList' display='flex'>
													<div className='roadmapItem'>
														<img src={RoadmapItem} className='roadmapIcon' />
														<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
															display='flex' alignItems='center'>Q4 2021: Validation</Box>
														<div className='roadmapContentList mt-28 font-14 color8'>
															<Box className='roadmapContent' display='flex'>
																<div className='timeheade'></div>
																<div className='ml-10 roadmapDesc'>
																	Market validation, feasibility, market infrastructure design and planning
																</div>
															</Box>
															<Box className='roadmapContent' display='flex'>
																<div className='timeheade'></div>
																<div className='ml-10 roadmapDesc'>
																	Team build out for technical, operational, executive, and market expertise
																</div>
															</Box>
															<Box className='roadmapContent' display='flex'>
																<div className='timeheade'></div>
																<div className='ml-10 roadmapDesc'>
																	Technology and executive capacity
																</div>
															</Box>
															<Box className='roadmapContent' display='flex'>
																<div className='timeheade'></div>
																<div className='ml-10 roadmapDesc'>
																	Partnerships, tools, and networks set up
																</div>
															</Box>
														</div>
													</div>
												</Box>
												:
												(
													currTimeTab == 1 ?
														<Box className='roadmapBoxList' display='flex'>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q1 2022: GrüenDAO launch</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			GrüenDAO IDO
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Launch the Initial GrüenDAO system and mirror carbon tokens
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			GrüenDAO Dapp launches
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Treasury formation for initial GRUENCC token launch
																		</div>
																	</Box>
																</div>
															</div>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q2 2022: GRUENCC token launch for carbon allowances</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Infrastructure for tokenization
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Initiation of formal infrastructure for carbon credit management
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Facility for carbon futures management, including brokerage, custody, audit, trading
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Launch of GruenCC reference token backed by carbon allowances
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Token listing on designated exchanges
																		</div>
																	</Box>
																</div>
															</div>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q2 2022: DAO governance</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			DAO proposal for governance mechanism for oversight, treasury management, disclosure, protocol management
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			DAO Proposal for protocol development
																		</div>
																	</Box>
																</div>
															</div>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q3 2022: DAO market infrastructure platform development</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Begin developing own native DAO tooling and platform for carbon credit asset management
																		</div>
																	</Box>
																</div>
															</div>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q3 2022: Carbon offset tokenization</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Carbon offset tokenization infrastructure
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Carbon offset governance evolution to encompass carbon offset asset type
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Carbon offset decision protocol
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Treasury formation for carbon offset token launch
																		</div>
																	</Box>
																</div>
															</div>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q4 2022: Carbon offset token launch</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Infrastructure for carbon offset tokenization
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Carbon offset credit pipeline
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Token listing
																		</div>
																	</Box>
																</div>
															</div>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q4 2022: DAO protocol and governance development</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Community consultation on strategic development, initiatives, management, and protocol evolution
																		</div>
																	</Box>
																</div>
															</div>
														</Box>
														:
														<Box className='roadmapBoxList' display='flex'>
															<div className='roadmapItem'>
																<img src={RoadmapItem} className='roadmapIcon' />
																<Box className='title color8 font-18 font-weight-b font-family-Futura-Bold'
																	display='flex' alignItems='center'>Q1 2023 onwards: Value chain integration</Box>
																<div className='roadmapContentList mt-28 font-14 color8'>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Value chain protocol development to vertically integrate the platform, including
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			New carbon asset type development
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Carbon credit creation
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Project development
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Carbon credit management
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Trading
																		</div>
																	</Box>
																	<Box className='roadmapContent' display='flex'>
																		<div className='timeheade'></div>
																		<div className='ml-10 roadmapDesc'>
																			Redemption
																		</div>
																	</Box>
																</div>
															</div>
														</Box>
												)
										}
									</div>
							}
						</Box>
					</Box>
				</div>
			</Box>
			<div className='footerBox mt-10'>
				<Box display='flex' justifyContent='center'>
					<div className='containerBox'>
						<Box display='flex' justifyContent='space-between' alignItems='center' className='footer-h'>
							<a className='' href='#top' >
								<img src={To} />
							</a>
							<div className=''>
								{
									isSmallerScreen ?
										<img src={LogoFM} height="30" />
										:
										<img src={LogoF} />
								}
							</div>
						</Box>
						<Box display='flex' className='footer mt-24'>
							<div className='footerItem'>
								<div className='title font-14 font-weight-b font-family-Futura-Bold'>Products</div>
								{/*<a className='desc mt-20 color1'
									href='' target='_blank'>Bonding</a>
								<a className='desc mt-20 color1'
									href='' target='_blank'>Staking</a>
									*/}
								<div className='desc mt-20 color1' onClick={handleClick(TransitionDown)}>Bonding</div>
								<div className='desc mt-20 color1' onClick={handleClick(TransitionDown)}>Staking</div>
							</div>
							<div className='footerItem'>
								<div className='title font-14 font-weight-b font-family-Futura-Bold'>Learn</div>
								<a className='desc mt-20 color1'
									href='https://docs.gruendao.finance/introduction/gruendao-introduction' target='_blank'>Documentation</a>
								<a className='desc mt-20 color1'
									href='https://medium.com/@gruendao' target='_blank'>Medium</a>
							</div>
							<div className='footerItem'>
								<div className='title font-14 font-weight-b font-family-Futura-Bold'>Join the community</div>
								<a className='desc mt-20 color1'
									href='https://discord.gg/w7nXvR9Vg3' target='_blank'>Join Discord</a>
								<a className='desc mt-20 color1'
									href='https://twitter.com/gruendao' target='_blank'>Twitter</a>
							</div>
						</Box>
					</div>
				</Box>
			</div>
		</div >
	)
}
