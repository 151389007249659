import React, { Component, useState } from "react";

import Home from './views/home';
import LoadingSplash from './components/Loading/LoadingSplash'
// import fonts from './assets/fonts/fonts.js'

class App extends Component {
  // var isAppLoading = true;
  // const [isAppLoading, setAppLoading] = useState(true);
  constructor(props) {
    super(props);
    this.state = { isAppLoading: true };
  }

  componentWillMount() {
    this.setState({
      isAppLoading: true
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isAppLoading: false
      });
    }, 2000);
  }

  render() {
    return (
      <div className="">
        {this.state.isAppLoading}
        {
        this.state.isAppLoading ?
          <LoadingSplash />
          :
          <Home />
        }
      </div>
    );
  }
}

export default App;